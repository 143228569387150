import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRTF } from "../rich-text";

const HeroRegular = ({ data }) => {

  const isTall = data.image.width/data.image.height < 1;

  const heroImage = getImage(data.image);


  return (
    <header className={`
        pt-16 md:pt-20 lg:pt-24
      `}
    >
      <div className="container">
        <div
          className={`flex-wrap flex items-center justify-center md:justify-between -mx-4 space-y-6 md:space-y-0`}
        >
          <div className={`
            ${data.textPosition === "left" ? "md:order-2" : ""}
            ${isTall ? "md:w-1/3 max-w-xs md:max-w-none" : "md:w-5/12 lg:w-6/12"}
            w-full px-4`}>
            <GatsbyImage 
              image={heroImage} 
              layout="fullWidth"
              objectFit="cover"
              loading="eager"
              className="h-full"
              alt={data.image.title}
            />
          </div>
          <div className={`
            ${data.textPosition === "left" ? "md:order-1" : ""}
            ${isTall ? "md:w-2/3" : "md:w-1/2 lg:w-6/12"}
            w-full px-4 max-w-4xl`}>
            {renderRTF(data.text)}
            {data.ctaText && data.ctaUrl && (
              <p>
                <a href={data.ctaUrl} className="btn btn-accent">
                  {data.ctaText}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeroRegular;

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { renderRTF } from "../rich-text";


const HeroImageBackground = ({ data }) => {

  const textColor = data.backgroundColor === 'bg-secondary' ? 'text-white' : 'text-secondary';

  const heroImage = getImage(data.image);

  if (data.textPosition === "center") {
    return (
      <div key={data.id} className={`${data.boxedBackground ? 'title mx-auto max-w-6xl rounded-20 overflow-hidden lg:rounded-40' : ''}`}>
        <section
          className="h-screen hero-bg bg-grey bg-opacity-80 flex flex-col justify-center bg-cover relative"
        >
          <div className="absolute inset-0 z-0">
            <GatsbyImage 
              className="h-full w-full" 
              imgClassName="w-full"
              loading="eager" 
              image={heroImage} 
              alt={data.image.title} 
            />
          </div>
          <div className={`${textColor} container flex flex-col max-w-3xl justify-center h-full relative z-10 text-center`}>
            <div className="self-center title-sm">
              {renderRTF(data.text)}
                {data.ctaText && data.ctaUrl && (
                  <p>
                    <a href={data.ctaUrl} className="btn btn-white">
                      {data.ctaText}
                    </a>
                  </p>
                )}
            </div>
          </div>
        </section>
      </div>
    );
  }

  if (data.textPosition === "left") {
    return (
      <div key={data.id} className={`${data.boxedBackground ? 'title mx-auto max-w-6xl rounded-20 overflow-hidden lg:rounded-40' : ''}`}>
        <section
          className="h-screen hero-bg bg-grey bg-opacity-80 flex flex-col justify-center bg-cover relative"
        >
          <div className="absolute inset-0 z-0"><GatsbyImage className=" h-full w-full" loading="eager" image={heroImage} /></div>
          <div className={`${textColor} container h-full relative z-10`}>
              <div className="flex flex-wrap h-full justify-start items-end -mx-4">
                <div className="w-full max-w-xl md:w-1/2 px-4 title-sm">
                  {renderRTF(data.text)}
                    {data.ctaText && data.ctaUrl && (
                      <p>
                        <a href={data.ctaUrl} className="btn btn-white">
                          {data.ctaText}
                        </a>
                      </p>
                    )}
                </div>
              </div>
            </div>
        </section>
      </div>
    );
  }
  if (data.textPosition === "right") {
    return (
      <div className={`${data.boxedBackground ? 'title mx-auto max-w-6xl rounded-20 overflow-hidden lg:rounded-40' : ''}`}>
        <section
          className="h-screen hero-bg bg-grey bg-opacity-80 flex flex-col justify-center bg-cover relative"
        >
          <div className="absolute inset-0 z-0">
            <GatsbyImage 
              className="h-full w-full" 
              imgClassName="w-full"
              loading="eager" 
              image={heroImage} 
              alt={data.image.title} 
            />
          </div>
          <div className={`${textColor} container h-full relative z-10`}>
            <div className="flex flex-wrap items-end justify-end -mx-4 h-full">
              <div className="w-full max-w-xl md:w-1/2 px-4 title-sm">
                {renderRTF(data.text)}
                  {data.ctaText && data.ctaUrl && (
                    <p>
                      <a href={data.ctaUrl} className="btn btn-white">
                        {data.ctaText}
                      </a>
                    </p>
                  )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  
};

export default HeroImageBackground;

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderSecondaryRTF } from "./rich-text";

const CallToActionSection = ({ data }) => {

    const ctaImage = getImage(data.image)
  
    return (
      <div className="relative bg-blue-light pb-10 lg:pb-20 xl:pb-24">
        <div className="bg-secondary absolute bottom-0 left-0 top-1/2 right-0 z-0"></div>
        <div className='mx-auto max-w-6xl rounded-20 overflow-hidden lg:rounded-40 relative z-10  transform translate-y-5 lg:translate-y-10 xl:translate-y-12'>
          <div
            className="cta-bg bg-grey bg-opacity-80 flex flex-col justify-center bg-cover relative"
          >
            <div className="absolute inset-0 z-0">
              <GatsbyImage className=" h-full w-full" image={ctaImage} alt={data.image.title} />
            </div>
            <div className={`text-white container flex flex-col max-w-3xl justify-center h-full relative z-10 text-center`}>
              <div className="self-center title-sm">
                {renderSecondaryRTF(data.text)}
                {data.ctaText && data.ctaUrl && (
                  <p>
                    <a href={data.ctaUrl} className="btn btn-white">
                      {data.ctaText}
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )

}

export default CallToActionSection
import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/common";
import SEO from "../components/Seo"

import Hero from "../components/hero/main";
import Section from "../components/content/section";
import SectionTitle from "../components/content/section-title";
import SectionWithCards from "../components/content/section-w-cards";
import SectionLogos from "../components/carousel/main";
import SectionMedia from "../components/content/section-media";
import SectionContact from "../components/content/section-contact";
import SectionHighlightsV2 from "../components/content/section-highlights-v2";
// import SubscribeSection from "../components/subscribe/email";
import SectionMultiRef from "../components/section-multi-ref";
import SectionWithItems from "../components/content/section-w-items";
import BlockquoteSection from "../components/content/section-blockquote";
import SectionSplashImage from "../components/content/section-splash-image";
import SectionWithCollapsible from "../components/content/section-w-collapsible";
import SectionLayeredImages from "../components/content/section-layered-images";
import SectionWithModules from "../components/content/section-w-modules";
import CallToActionSection from "../components/call-to-action";

const CommonPageTemplate = ({ data }) => {
  const page = data.page.edges[0].node
  const pageBody = page.pageBody

  return (
    <Layout>
      <SEO title={page.title} description={page.description} canonical />
      {/* space-y-20 md:space-y-24 lg:space-y-28 xl:space-y-36  */}
      <div className="page-content">
        {pageBody.map((item) => {
          switch (item.__typename) {
            case "ContentfulHero":
              return <Hero data={item} key={`${item.id}`} />
            case "ContentfulSectionRegular":
              return <Section data={item} key={`${item.id}`} />
            case "ContentfulSectionTitle":
              return <SectionTitle data={item} key={`${item.id}`} />
            case "ContentfulSectionCallToAction":
              return <CallToActionSection data={item} key={`${item.id}`} />
            case "ContentfulSectionCards":
              return <SectionWithCards data={item} key={`${item.id}`} />
            case "ContentfulSectionLogos":
              return <SectionLogos data={item} key={`${item.id}`} />
            case "ContentfulSectionMedia":
              return <SectionMedia data={item} key={`${item.id}`} />
            case "ContentfulSectionHighlight":
              return <SectionHighlightsV2 data={item} key={`${item.id}`} />
            // case "ContentfulSectionSubscribe":
            //   return <SubscribeSection data={item} key={`${item.id}`} />
            case "ContentfulSectionMultiRef":
              return <SectionMultiRef data={item} key={`${item.id}`} />
            case "ContentfulSectionColumnCards":
              return <SectionWithItems data={item} key={`${item.id}`} />
            case "ContentfulSectionBlockquote":
              return <BlockquoteSection data={item} key={`${item.id}`} />
            case "ContentfulSectionGetInTouch":
              return <SectionContact data={item} key={`${item.id}`} />
            case "ContentfulSectionSplashImageCustom":
              return <SectionSplashImage data={item} key={`${item.id}`} />
            case "ContentfulSectionCollapsibleCustom":
              return <SectionWithCollapsible data={item} key={`${item.id}`} />
            case "ContentfulSectionLayeredImages":
              return <SectionLayeredImages data={item} key={`${item.id}`} />
            case "ContentfulSectionModules":
              return <SectionWithModules data={item} key={`${item.id}`} />
            default:
              return ''
          }
        })}
        {/* <div /> */}
      </div>
    </Layout>
  )
}

export default CommonPageTemplate

export const pageQuery = graphql`
  query CommonPageBySlug($slug: String!) {
    pages: allContentfulPage {
      edges {
        node {
          slug
          title
          name
        }
      }
    }
    page: allContentfulPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          description
          title
          slug
          pageBody {
            ... on ContentfulHero {
              __typename
              id
              name
              ctaText
              ctaUrl
              text {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    title
                    file {
                      url
                      fileName
                      details {
                        image {
                          height
                          width
                        }
                      }
                    }
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              textPosition
              image {
                title
                gatsbyImageData(
                  # width: 1440
                  # height: 720
                  layout: CONSTRAINED 
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                width
                height
              }

              hasImageAsBackground
              backgroundColor
              boxedBackground
            }
            ... on ContentfulSectionRegular {
              __typename
              id
              name
              ctaText
              ctaUrl
              backgroundColor
              image {
                title
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              text {
                raw
              }
              titleText {
                raw
              }
              textPosition
            }
            ... on ContentfulSectionTitle {
              __typename
              id
              maxWidth
              ctaText
              ctaUrl
              backgroundColor
              text {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    title
                    file {
                      url
                      fileName
                      details {
                        image {
                          height
                          width
                        }
                      }
                    }
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            ... on ContentfulSectionCallToAction {
              __typename
              id
              name
              ctaText
              ctaUrl
              image {
                title
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              text {
                raw
              }
            }
            ... on ContentfulSectionCards {
              __typename
              id
              name
              cardCentred
              cardType
              text {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    title
                    file {
                      url
                      fileName
                      details {
                        image {
                          height
                          width
                        }
                      }
                    }
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              backgroundColor
             
              cards {
                heading
                subHeading
                icon {
                  file {
                    url
                  }
                  title
                }
                link
              }
            }
            ... on ContentfulSectionLogos {
              __typename
              id
              name
              sectionType
              id
              titleText {
                raw
              }
              images {
                id
                file {
                  url
                }
                title
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            ... on ContentfulSectionMedia {
              name
              backgroundColor
              mediaItems {
                image {
                  file {
                    url
                  }
                  title
                  gatsbyImageData(
                    width: 166
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
                #link
                heading
              }
              text {
                raw
              }
            }
            ... on ContentfulSectionHighlight {
              __typename
              id
              backgroundColor
              text {
                raw
              }
              image {
                title
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              items {
                heading
                subHeading
              }
            }
            # ... on ContentfulSectionSubscribe {
            #   text {
            #     raw
            #   }
            #   backgroundColor
            # }
            ... on ContentfulSectionMultiRef {
              __typename
              id
              name
              blocks {
                backgroundColor
                image {
                  title
                  gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
                icon {
                  file {
                    url
                  }
                }
                items {
                  heading
                  subHeading
                }
                text {
                  raw
                  # references {
                  #   ... on ContentfulAsset {
                  #     contentful_id
                  #     __typename
                  #     title
                  #     fluid(maxWidth: 1200) {
                  #       ...GatsbyContentfulFluid_withWebp
                  #     }
                  #     file {
                  #       url
                  #       fileName
                          # details {
                          #   image {
                          #     height
                          #     width
                          #   }
                          # }
                  #     }
                  #   }
                  # }
                }
              }
            }
            ... on ContentfulSectionColumnCards {
              id
              __typename
              backgroundColor
              itemType
              image {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              items {
                icon {
                  file {
                    url
                  }
                  title
                }
                subHeading
                heading
              }
              text {
                raw
                # references {
                #   ... on ContentfulAsset {
                #     contentful_id
                #     __typename
                #     title
                #     fluid(maxWidth: 1200) {
                #       ...GatsbyContentfulFluid_withWebp
                #     }
                #     file {
                        # details {
                        #   image {
                        #     height
                        #     width
                        #   }
                        # }
                #       url
                #       fileName
                #     }
                #   }
                # }
              }
            }
            ... on ContentfulSectionBlockquote {
              __typename
              id
              blockquote: text
              author {
                avatar {
                  gatsbyImageData(
                    width: 192
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
                lastName
                firstName
                title
              }
            }
            ... on ContentfulSectionGetInTouch {
              id
              __typename
              text {
                raw
              }
            }
            ... on ContentfulSectionSplashImageCustom {
              id
              __typename
              image {
                title
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            ... on ContentfulSectionCollapsibleCustom {
              __typename
              id
              name
              backgroundColor
              image {
                title
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              items {
                heading
                subHeading
              }
              titleText {
                raw
              }
            }
            ... on ContentfulSectionLayeredImages {
              __typename
              id
              name
              titleText {
                raw
              }
              imageLeft {
                title
                gatsbyImageData(
                  width: 900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              imageRight {
                title
                gatsbyImageData(
                  width: 700
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            ... on ContentfulSectionModules {
              __typename
              id
              name
              sectionBody {
                ... on ContentfulBlockImageRow {
                  __typename
                  id
                  name
                  images {
                    file {
                      url
                    }
                    description
                  }
                }
                ... on ContentfulBlockItemRow {
                  __typename
                  id
                  name
                  itemType
                  items {
                    heading
                    subHeading
                    icon {
                      file {
                        url
                      }
                    }
                  }
                }
                ... on ContentfulSectionTitle {
                  __typename
                  id
                  name
                  ctaUrl
                  ctaText
                  text {
                    raw
                    references {
                      file {
                        contentType
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
